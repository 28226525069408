// // import { PayPalScriptOptions } from '@paypal/paypal-js';
// // import { PayPalScriptProvider } from '@paypal/react-paypal-js';
// import React, { useEffect, useState } from 'react';
// import { Button, Container } from 'react-bootstrap';
// import { connect } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Layout from '../../layout/Layout';
// import { selectFreePlan } from '../../redux/actions/plansAction';
// import CustomPlanSelection from './custom-plan-selection-modal';
// import PaypalWindow from './paypal-window';
// import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
// import { PayPalScriptOptions } from '@paypal/paypal-js';

// const PaymentPage = (props: any) => {
//     const location = useLocation();
//     console.log(location)
//     const amtPayble = location.state.amount;
//     const planType = location.state.type;
//     const planId = location.state.id;
//     console.log(planId,planType,amtPayble)
//     // const paypalScriptOptions: PayPalScriptOptions = {
//     //     "clientId": "AVyoygShwXPmr4HTQrNaKlrtXmZmUQP2HF05l7sEV8kDciYJgDVSwivtqL1sB9gr6TaCYY039aH3NRqL", 
//     //     currency: 'USD',
//     //     intent: 'CAPTURE',
//     //     // vault: true,
//     // };
//     const initialOptions = {
//         clientId: "AVyoygShwXPmr4HTQrNaKlrtXmZmUQP2HF05l7sEV8kDciYJgDVSwivtqL1sB9gr6TaCYY039aH3NRqL",
//         currency: "USD",
//         intent: "SUBSCRIPTION",
//         vault:true
//     };
    
//     return (
//         <div>
//             <Layout >
//                 <div className="welcome">
//                     <Container className='text-center'>
//                         <h3> Amount to be paid:  R {amtPayble}</h3>
//                         {/* <PayPalScriptProvider options={paypalScriptOptions}>    */}
//                        <PayPalScriptProvider options={initialOptions}>

//                             <PaypalWindow amount={amtPayble} planId={'P-9AH71888N59009135MYHFJZA'} planCode={planType} />
//                         </PayPalScriptProvider>
//                         {/* <PayPalScriptProvider options={paypalScriptOptions}>
//                         <PaypalWindow amount={amtPayble} planId={planId} planCode={planType} />
//                         </PayPalScriptProvider> */}
//                         {props.message && <div className={(props.message && props.message.status === 'success') ? 'text-success' : 'text-danger'}>{props.message.data}</div>}
//                    </Container>
//                 </div>
//             </Layout>
//         </div>
//     );
// };
// const mapDispatchToProps = (dispatch: any) => ({
//     selectFreePlan: (data: any) => dispatch(selectFreePlan(data))
// });
// const mapStateToProps = (state: any) => {
//     return {
//         selectPlanDetailsData: (state.PlansReducer && state.PlansReducer.selectPlanDetailsData) ? state.PlansReducer.selectPlanDetailsData : null,
//         message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
//     };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
import { PayPalScriptOptions } from '@paypal/paypal-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { selectFreePlan } from '../../redux/actions/plansAction';
import CustomPlanSelection from './custom-plan-selection-modal';
import PaypalWindow from './paypal-window';

const PaymentPage = (props: any) => {
    const location = useLocation();
   
    const amtPayble = location.state.amount;
    const planType = location.state.type;
    const planId = location.state.id;
    const paypalPlanId=location.state.paypalPlanId
    const  custom_id=location.state.custom_id
    const paypalScriptOptions: PayPalScriptOptions = {
        "client-id": process?.env?.REACT_APP_PAYPAL_CLIENT_ID || '', 
        currency: "USD",
        intent: "subscription",
        vault: true,
    };
    return (
        <div>
            <Layout >
                <div className="welcome ">
                    <Container className='text-center w-50'>
                        <h3> Amount to be paid:  $ {amtPayble}</h3>
                        <PayPalScriptProvider options={paypalScriptOptions}>
                            <PaypalWindow amount={amtPayble} planId={planId} planCode={planType} paypalPlanId={paypalPlanId} custom_id={custom_id} />
                        </PayPalScriptProvider>
                        {props.message && <div className={(props.message && props.message.status === 'success') ? 'text-success' : 'text-danger'}>{props.message.data}</div>}
                   </Container>
                </div>
            </Layout>
        </div>
    );
};
const mapDispatchToProps = (dispatch: any) => ({
    selectFreePlan: (data: any) => dispatch(selectFreePlan(data))
});
const mapStateToProps = (state: any) => {
    return {
        selectPlanDetailsData: (state.PlansReducer && state.PlansReducer.selectPlanDetailsData) ? state.PlansReducer.selectPlanDetailsData : null,
        message: (state.CommonMessageReducer && state.CommonMessageReducer.message) ? state.CommonMessageReducer.message : null,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);